import type { JBrand } from "@/server/routers/organizations/brands";
import { useContext } from "react";
import { createContext } from "react";

export interface OrganizationBrands {
  active: JBrand;
  available: number;
}

export const OrganizationBrandsContext = createContext<OrganizationBrands | null>(null);
export const OrganizationBrandsDispatchContext = createContext<
  (brands: OrganizationBrands | null) => void
>(() => {});

export function useOrganizationBrands() {
  return useContext(OrganizationBrandsContext);
}

export function useOrganizationBrandsDispatch() {
  return useContext(OrganizationBrandsDispatchContext);
}
